import React, { Component } from "react";
import PropTypes, { string } from "prop-types";
import { withNamespaces, Trans } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Badge, Button, Modal } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";

import SidebarRun from "./Sidebar.run";
import SidebarUserBlock from "./SidebarUserBlock";
import { EnumTypeClinic } from "../Utils/Enuns/TypeClinic";
import { TypeCompany } from "../Utils/Enuns/TypeCompany";
import Menu from "../../Menu.js";
import { URL_Screens } from "../Services/screensService";
import axios from "axios";
import { history } from "../../history";
import { compareDesc } from "date-fns";
import AlertPermissionModal from "../../common/components/Modal/AlertPermissionModal.js";
import { EnumPermission } from "../Utils/Enuns/Permissions.js";
import { json } from "d3";
import { URL_Plan } from "../Services/planService.js";
import valuePermission from "../Common/VerifyPermission.js";
/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li
    className="nav-heading"
  // style={{ paddingTop: "2px", paddingBottom: "2px" }}
  >
    <span>
      <Trans i18nKey={item.translate}>{item.heading}</Trans>
    </span>
  </li>
);

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
  item.viewPermission == true ? (
    <li className={isActive ? "active" : ""}>
      <Link to={item.path} title={item.name} style={{ whiteSpace: "normal" }}>
        {item.label && (
          <Badge tag="div" className="float-right" color={item.label.color}>
            {item.label.value}
          </Badge>
        )}
        {item.icon && <em className={item.icon}></em>}
        <span>
          <Trans i18nKey={item.translate}>{item.name}</Trans>
        </span>
      </Link>
    </li>
  ) : null);

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
  <li
    className={isActive ? "active" : ""}
  // style={{ paddingTop: "2px", paddingBottom: "2px" }}
  >
    <div
      className="nav-item"
      onClick={handler}
    //   style={{ paddingTop: "20px", paddingBottom: "20px" }}
    >
      {item.label && (
        <Badge tag="div" color={item.label.color}>
          {item.label.value}
        </Badge>
      )}
      {item.icon && <em className={item.icon}></em>}
      <span>
        <Trans i18nKey={item.translate}>{item.name} </Trans>
      </span>
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className="sidebar-nav sidebar-subnav">
        {children}
      </ul>
    </Collapse>
  </li>
);

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">{item.name}</li>
);

class Sidebar extends Component {
  constructor(props) {
    super(props);
    let clinic = JSON.parse(localStorage.getItem("ClinicData"))
      ? JSON.parse(localStorage.getItem("ClinicData"))
      : "";
    this.state = {
      collapse: {},
      clinic: clinic,
    };
  }

  componentDidMount() {
    let typeCompany = localStorage.getItem("TypeCompany");
    // pass navigator to access router api
    SidebarRun(this.navigator, this.closeSidebar);
    // prepare the flags to handle menu collapsed states
    this.buildCollapseList();
    // Listen for routes changes in order to hide the sidebar on mobile
    this.props.history.listen(this.closeSidebar);
    this.removeBarberShopMenu();

    this.enableProfiles();
    if (typeCompany == TypeCompany.provider) this.removeMenusForProvider();
    this.expiredTest();
    this.consultPermissions();
  }

  consultPermissions = async () => {
    await axios.get(`${URL_Plan}/GetPermission`).then(e => {
      const { data } = e
      localStorage.setItem('PlanPermission', JSON.stringify(data))
    })
  }
  expiredTest() {
    let plan = JSON.parse(localStorage.getItem("Plan"));
    if (plan != null) {
      var today = new Date();
      var expired = new Date();
      expired.setDate(today.getDate() - 5);

      const expiretTest = compareDesc(Date.parse(plan.effectiveDate), expired);
      if (expiretTest == 1) {
        Menu.map((item, i) => {
          if (this.itemType(item) === "submenu")
            item.submenu.map((item, i) => {
              item.viewPermission = false;
            });
        });
        history.push("/dashboardv1");
        this.setState({});
      }
    }
  }

  removeBarberShopMenu() {
    const { clinic } = this.state;
    if (
      clinic != "" &&
      (clinic.typeClinic == EnumTypeClinic.barbershop ||
        clinic.typeClinic == EnumTypeClinic.beautysalon)
    ) {
      for (var i = Menu.length - 1; i >= 0; i--) {
        if (
          Menu[i].name === "Tratamento" ||
          Menu[i].name === "Atestado" ||
          Menu[i].name === "Receituário"
        ) {
          Menu.splice(i, 1);
        }
      }
    }
  }

  removeMenusForProvider() {
    for (var i = Menu.length - 1; i >= 0; i--) {
      if (
        Menu[i].name === "Tratamento" ||
        Menu[i].name === "Atestado" ||
        Menu[i].name === "Dashboard"
      ) {
        Menu.splice(i, 1);
      }
    }
    for (let index = 0; index < Menu.length; index++) {
      if (Menu[index].submenu != undefined) {
        Menu[index].submenu.map((item, i) => {
          if (
            item.name != "Estabelecimento" &&
            item.name != "Agendamentos" &&
            item.name != "Financeiro" &&
            item.name != "Centro de Custo"
          )
            item.viewPermission = false;
        });
      }
    }
  }

  enableProfiles = async () => {
    const idProfile = localStorage.getItem("idProfile");
    const { clinic } = this.state;
    let screensData;
    if (idProfile > 0) {
      await axios
        .get(URL_Screens, {
          params: {
            idProfile: idProfile,
          },
        })
        .then((resp) => {
          const { data } = resp;
          screensData = data;
        });

      Menu.map((item, i) => {
        if (this.itemType(item) === "submenu")
          item.submenu.map((item, i) => {
            screensData.forEach((element) => {
              if (element.screens.codeScreen == item.codeScreen)
                item.viewPermission = true;
            });
          });
      });
    } else if (clinic != "" && clinic.typeClinic == EnumTypeClinic.barbershop) {
      Menu.map((item, i) => {
        if (this.itemType(item) === "submenu")
          item.submenu.map((item, i) => {
            if (
              item.name == "Medicamentos" ||
              item.name == "Procedimentos HOF" ||
              item.name == "Procedimentos Toxina" ||
              item.name == "Receituário" ||
              item.name == "Anamnese" ||
              item.name == "Convênio"
            )
              item.viewPermission = false;
            else item.viewPermission = true;
          });
      });
    } 
    else if (clinic != "" && clinic.typeClinic == EnumTypeClinic.beautysalon) {
      Menu.map((item, i) => {
        if (this.itemType(item) === "submenu")
          item.submenu.map((item, i) => {
            if (
              item.name == "Medicamentos" ||
              item.name == "Procedimentos HOF" ||
              item.name == "Procedimentos Toxina" ||
              item.name == "Receituário" ||
              item.name == "Anamnese" ||
              item.name == "Convênio"
            )
              item.viewPermission = false;
            else item.viewPermission = true;
          });
      });
    } 
    else {
      Menu.map((item, i) => {
        if (this.itemType(item) === "submenu")
          item.submenu.map((item, i) => {
            item.viewPermission = true;
          });
      });
    }
    this.setState({});
  };

  closeSidebar = () => {
    this.props.actions.changeSetting("asideToggled", false);
  };

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  buildCollapseList = () => {
    let collapse = {};
    Menu.filter(({ heading }) => !heading).forEach(
      ({ name, path, submenu }) => {
        collapse[name] = this.routeActive(
          submenu ? submenu.map(({ path }) => path) : path
        );
      }
    );
    this.setState({ collapse });
  };

  navigator = (route) => {
    this.props.history.push(route.replace("#", "")); // remove '#' in case of use HashRouter
  };

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some((p) => this.props.location.pathname.indexOf(p) > -1);
  }

  toggleItemCollapse(stateName) {
    // eslint-disable-next-line
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName)
        this.setState({
          collapse: {
            [c]: false,
          },
        });
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName],
      },
    });
  }

  getSubRoutes = (item) => item.submenu.map(({ path }) => path);

  /** map menu config to string to determine which element to render */
  itemType = (item) => {
    if (item.heading) return "heading";
    if (!item.submenu) return "menu";
    if (item.submenu) return "submenu";
  };


  checkPlanPermission = (pathsub, path) => {
    if (pathsub.includes('Pacotes')) {
      return valuePermission(EnumPermission.PACKAGEREGISTRATION)

    } else if (path.includes('Nota Fiscal')) {
      return valuePermission(EnumPermission.NFSE)
    }
    else if (pathsub.includes('Gateway de Pagamentos')) {
      return valuePermission(EnumPermission.PAYMENTGATEWAY)
    }
    else if (pathsub.includes('Página de Links')) {
      return valuePermission(EnumPermission.LINKSPAGE)
    }
    else if (path.includes('Atestado')) {
      return valuePermission(EnumPermission.ATTESTATION)
    }
    else if (pathsub.includes('Anamnese')) {
      return valuePermission(EnumPermission.ANAMNESES)
    }
    else if (pathsub.includes('Procedimentos HOF')) {
      return valuePermission(EnumPermission.HOFPROCEDURES)
    }
    else if (pathsub.includes('Estoque')) {
      return valuePermission(EnumPermission.STOCK)
    }
    else if (pathsub.includes('Pesquisa de Satisfação')) {
      return valuePermission(EnumPermission.SATISFACTIONSURVEY)
    }
    else if (pathsub.includes('Planos de Assinatura')) {
      return valuePermission(EnumPermission.REGISTRATIONSUBSCRIPTIONPLANS)
    }
    else {
      return true
    }
  }

  Modal = () => {
    this.setState({ modal: !this.state.modal })
  }

  render() {
    return (
      <div>
        <aside className="aside-container">
          {/* START Sidebar (left) */}
          <div className="aside-inner">
            <nav data-sidebar-anyclick-close="" className="sidebar">
              {/* START sidebar nav */}
              <ul
                className="sidebar-nav"
                style={{ paddingTop: "4px", paddingBottom: "4px" }}
              >
                {/* START user info */}
                <li className="has-user-block">
                  <SidebarUserBlock />
                </li>
                {/* END user info */}

                {/* Iterates over all sidebar items */}
                {Menu.map((item, i) => {
                  // heading
                  if (this.itemType(item) === "heading")
                    return <SidebarItemHeader item={item} key={i} />;
                  else {
                    if (this.itemType(item) === "menu")
                      return (
                        <SidebarItem
                          isActive={this.routeActive(item.path)}
                          item={item}
                          key={i}
                        />
                      );
                    if (this.itemType(item) === "submenu")
                      return [
                        <SidebarSubItem
                          item={item}
                          isOpen={this.state.collapse[item.name]}
                          handler={this.toggleItemCollapse.bind(
                            this,
                            item.name
                          )}
                          isActive={this.routeActive(this.getSubRoutes(item))}
                          key={i}
                        >
                          <SidebarSubHeader item={item} key={i} />
                          {item.submenu.map((subitem, i) => (
                            this.checkPlanPermission(subitem.name, item.name) ?
                              <SidebarItem
                                key={i}
                                item={subitem}
                                isActive={this.routeActive(subitem.path)}
                              />
                              :<li
                                className={this.routeActive(subitem.path) ? "active" : ""}
                              ><a
                                onClick={e => this.Modal()}
                              >
                                  <span >
                                    {subitem.name}
                                  </span>
                                </a></li>
                          ))}
                        </SidebarSubItem>,
                      ];
                  }
                  return null; // unrecognized item
                })}
              </ul>
              {/* END sidebar nav */}
              <br />
            </nav>
          </div>
          {/* END Sidebar (left) */}
        </aside>
        <AlertPermissionModal
          modal={this.state.modal}
          Modal={this.Modal}
        />
      </div>
    );
  }
}

Sidebar.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translations")(withRouter(Sidebar)));




